import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MFieldInput, MFieldRadioGroup, MFieldRadioGroupOption } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import { useHistory } from '../../shared/use-history'
import * as yup from 'yup'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import { withFormikCompareFix } from '../../shared/formik'
import { LoadingSwitchPanel } from '../../shared/loading-switch-panel'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MutationErrorMessage, QueryErrorMessage } from '../../shared/react-apollo'
import { FormikDialog } from '../../shared/dialog/react-formik-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { ValidationIssues } from '../../shared/validation-issues'
import { TENANT_SETTINGS, UPDATE_TENANT_SETTINGS } from '../../gql/inspect'
import { useMutation, useQuery } from '@apollo/client'
import { Maybe } from '../../shared/typescript'

export interface TenantSettingsDialogForm {
  compressionSettings: {
    defaultRate: number
    parallelProcessNumber: number
    overwriteOption: Maybe<boolean> | undefined
    thumbnailSmartResize: Maybe<boolean> | undefined
  }
}

export const TenantSettingsDialog = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { setting: selectedSetting } = useParams() as { setting: string }

  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlert = (alertType: AlertType) => setAlert(alertType)
  const handleAlertClose = () => setAlert(null)

  const settingsQuery = useQuery(TENANT_SETTINGS)
  const settings = settingsQuery.data?.inspectTenant?.settings?.compressionSettings

  const defaultRate = settings?.defaultRate ?? 0
  const parallelProcessNumber = settings?.parallelProcessNumber ?? 0
  const overwriteOption = settings?.overwriteOption ?? false
  const thumbnailSmartResize = settings?.thumbnailSmartResize ?? false

  const [initialValues] = useLocalState<TenantSettingsDialogForm>(
    () =>
      withFormikCompareFix({
        compressionSettings: {
          defaultRate: defaultRate,
          parallelProcessNumber: parallelProcessNumber,
          overwriteOption: overwriteOption,
          thumbnailSmartResize: thumbnailSmartResize,
        },
      }),
    [settings],
  )

  const [schema] = useState(() =>
    yup.object({
      id: yup.string(),
      selectedSetting: yup.string(),
      value: yup.string() || yup.bool(),
    }),
  )

  const handleClose = () => {
    h.push('/compression', { replace: true })
  }

  const [updateInspectTenantSettings, { loading: saveLoading }] = useMutation(UPDATE_TENANT_SETTINGS)

  const handleSave = async (form: TenantSettingsDialogForm) => {
    if (saveLoading) {
      return
    }

    handleAlert('edit')
    await updateInspectTenantSettings({
      variables: {
        input: { compressionSettings: form?.compressionSettings },
      },
    })
    handleClose()
  }

  return (
    <>
      <AlertDialog alert={alert} entity={'InspectSetting'} handleClose={handleAlertClose} />
      <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSave}>
        <FormikDialog title={t('EditSetting')} submit={t('Save')} open onClose={handleClose} minWidth='sm'>
          <SavingSwitchPanel mutation={[]}>
            <LoadingSwitchPanel query={settingsQuery}>
              <ValidationIssues />
              <QueryErrorMessage query={{ loading: settingsQuery.loading, error: settingsQuery.error }} />
              <MutationErrorMessage mutation={[]} />

              {selectedSetting === 'defaultRate' && (
                <>
                  <div style={{ margin: '8px' }}>{t('DefaultRate')}</div>
                  <Field component={MFieldConnector} name={`compressionSettings.${selectedSetting}`} label={t('Value')}>
                    <MFieldInput />
                  </Field>
                </>
              )}

              {selectedSetting === 'parallelProcessNumber' && (
                <>
                  <div style={{ margin: '8px' }}>{t('ParallelProcessNumber')}</div>
                  <Field component={MFieldConnector} name={`compressionSettings.${selectedSetting}`} label={t('Value')}>
                    <MFieldInput />
                  </Field>
                </>
              )}

              {selectedSetting === 'overwriteOption' && (
                <Field
                  name='compressionSettings.overwriteOption'
                  value='compressionSettings.overwriteOption'
                  type='checkbox'
                  component={MFieldConnector}
                  label={t('OverwriteOptionActive')}
                >
                  <MFieldRadioGroup>
                    <MFieldRadioGroupOption value={1}>{t('Activated')}</MFieldRadioGroupOption>
                    <MFieldRadioGroupOption value={0}>{t('NotActivated')}</MFieldRadioGroupOption>
                  </MFieldRadioGroup>
                </Field>
              )}

              {selectedSetting === 'thumbnailSmartResize' && (
                <Field
                  name='compressionSettings.thumbnailSmartResize'
                  value='compressionSettings.thumbnailSmartResize'
                  type='checkbox'
                  component={MFieldConnector}
                  label={t('ThumbnailSmartResize')}
                >
                  <MFieldRadioGroup>
                    <MFieldRadioGroupOption value={1}>{t('Activated')}</MFieldRadioGroupOption>
                    <MFieldRadioGroupOption value={0}>{t('NotActivated')}</MFieldRadioGroupOption>
                  </MFieldRadioGroup>
                </Field>
              )}
            </LoadingSwitchPanel>
          </SavingSwitchPanel>
        </FormikDialog>
      </Formik>
    </>
  )
}