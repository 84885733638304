import React, { useState } from 'react'
import { MFlexBlock, MText } from '@mprise/react-ui'
import { Button, CardHeader, CircularProgress, IconButton, ListItem, ListItemText, Switch } from '@mui/material'
import { IconClose, IconInfo } from '../../icons'
import Modal from './modal'
import { useHistory } from '../../shared/use-history'
import { useMutation } from '@apollo/client'
import { UPDATE_TENANT_SETTINGS } from '../../gql/inspect'

export const TenantSettingCard = ({
  setting,
  title,
  value,
  text,
}: {
  setting: String
  title: String
  value: any
  text: String
}) => {
  const h = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const handleEdit: React.MouseEventHandler<HTMLElement> = () => h.push(`/compression/edit/${setting}`)

  const openInfoModal: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation()
    setIsOpen(true)
  }

  const closeInfoModal: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation()
    setIsOpen(false)
  }

  return (
    <>
      <ListItem button onClick={handleEdit}>
        <ListItemText
          primary={title}
          secondary={`Current Value: ${value === null || value === undefined ? '' : value}`}
        />
        <IconButton onClick={openInfoModal}>
          <IconInfo />
        </IconButton>
      </ListItem>

      <Modal isOpen={isOpen}>
        <Button sx={{ float: 'right' }} onClick={closeInfoModal}>
          <IconClose />
        </Button>
        <CardHeader title={title} color={'black'} style={{ paddingTop: '6px' }} />
        <MText block style={{ marginLeft: 16 }}>
          {text}
        </MText>
      </Modal>
    </>
  )
}

export const TenantSettingCardBoolean = ({
  setting,
  title,
  value,
  text,
  loading,
}: {
  setting: string
  title: string
  value: any
  text: string
  loading: boolean
}) => {
  const [updateInspectTenantSettings, { loading: saveLoading }] = useMutation(UPDATE_TENANT_SETTINGS)

  const handleEdit = () => {
    updateInspectTenantSettings({
      variables: {
        input: { compressionSettings: { [setting]: !value } },
      },
    })
  }

  return (
    <ListItem button onClick={handleEdit}>
      <ListItemText primary={title} secondary={text} />
      <MFlexBlock gap={2} alignItems='center' minWidth='auto'>
        {(loading || saveLoading) && <CircularProgress size='20px' />}
        <Switch checked={value} disabled={loading || saveLoading} onChange={handleEdit} />
      </MFlexBlock>
    </ListItem>
  )
}