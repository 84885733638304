import React, { ReactNode } from 'react'

interface ModalType {
  children?: ReactNode
  isOpen: boolean
}

export default function Modal(props: ModalType) {
  return (
    <>
      {props.isOpen && (
        <div
          style={{
            zIndex: 1000,
            width: `-webkit-fill-available`,
            display: `block`,
            padding: `1rem`,
            position: `absolute`,
            top: `0`,
            background: `rgba(0, 0, 0, 0.6)`,
            justifyContent: `center`,
            alignItems: `center`,
            height: `100%`,
          }}
        >
          <div
            style={{
              marginTop: `7rem`,
              fontSize: `1rem`,
              maxWidth: `80%`,
              display: `block`,
              padding: `1rem`,
              background: `white`,
              borderRadius: `1rem`,
            }}
          >
            {props.children}
          </div>
        </div>
      )}
    </>
  )
}