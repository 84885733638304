import { gql } from '@apollo/client'

export const TENANT_SETTINGS = gql`
  query tenantSettings {
    inspectTenant {
      settings {
        compressionSettings {
          defaultRate
          parallelProcessNumber
          overwriteOption
          thumbnailSmartResize
          thumbnailFixedSize {
            width
            height
          }
        }
      }
    }
  }
`

export const UPDATE_TENANT_SETTINGS = gql`
  mutation updateInspectTenantSettings($id: ID!, $input: InspectTenantSettingsInput!) {
    updateInspectTenantSettings(id: $id, input: $input) {
      settings {
        compressionSettings {
          defaultRate
          parallelProcessNumber
          overwriteOption
          thumbnailSmartResize
          thumbnailFixedSize {
            width
            height
          }
        }
      }
    }
  }
`