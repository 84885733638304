import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { MText, MTextColor } from '@mprise/react-ui'
import { AlertDialog } from '../../shared/alert-dialog'
import { QueryErrorMessage } from '../../shared/react-apollo'
import { TenantSettingCard, TenantSettingCardBoolean } from './card'
import { MPageTitle } from '../../shared/page-title'
import { TENANT_SETTINGS } from '../../gql/inspect'
import { useQuery } from '@apollo/client'
import { List } from '@mui/material'

export const TenantSettingsPage = () => {
  const { t } = useTranslation()

  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlertClose = () => setAlert(null)

  const { data, loading, error } = useQuery(TENANT_SETTINGS)

  const compressionSettings = data?.inspectTenant?.settings?.compressionSettings
  const defaultRate = compressionSettings?.defaultRate
  const parallelProcessNumber = compressionSettings?.parallelProcessNumber
  const overwriteOption = compressionSettings?.overwriteOption
  const thumbnailSmartResize = compressionSettings?.thumbnailSmartResize

  return (
    <>
      <Helmet title={t('Compression settings')} />
      <AlertDialog alert={alert} entity={`TenantSettings`} handleClose={handleAlertClose} />

      <MPageTitle label={t('Compression settings')} />
      <List style={{ margin: '0 0.5rem' }}>
        <QueryErrorMessage query={{ loading, error }} />
        {!loading && data ? (
          <MText block textVariant='content bold' textColor={MTextColor.disabled}>
            {t(`NOTIFICATION_NO_RESULTS`)}
          </MText>
        ) : null}
        <TenantSettingCard
          setting={'defaultRate'}
          title={t('DefaultRate')}
          value={defaultRate}
          text={t('defaultRateInfoText')}
        />
        <TenantSettingCard
          setting={'parallelProcessNumber'}
          title={t('ParallelProcessNumber')}
          value={parallelProcessNumber}
          text={t('parallelProcessNumberInfoText')}
        />
        <TenantSettingCardBoolean
          setting={'overwriteOption'}
          title={t('OverwriteOption')}
          value={overwriteOption}
          text={t('overwriteOptionInfoText')}
          loading={loading}
        />
        <TenantSettingCardBoolean
          setting={'thumbnailSmartResize'}
          title={t('ThumbnailSmartResize')}
          value={thumbnailSmartResize}
          text={t('thumbnailSmartResizeInfoText')}
          loading={loading}
        />
      </List>
    </>
  )
}